<template>
  <div class="joinRoom">
    <div class="myRoom" v-if="Object.keys(chatInfo).length > 0">
      <div class="my-room-top">
        <div class="myRoomTitle"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('我的聊天室')}} :</div>
        <div class="my-top-key"> 
          <img src="@/assets/bracelet_img/key.png" alt=""> X {{ Number(userInfo.free_key) + Number(userInfo.pay_key) }} 
          <van-button :loading="regainLoading"
                      :loading-text="$t('购买中...')"
                      @click="showNumKeys = true"
                      class="my-key-class"
                      v-if="authorShow"
                      :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('购买钥匙')}}</van-button>
          <van-button class="my-key-class"
                      v-else
                      @click="author"
                      :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('购买钥匙授权')}}</van-button>
        </div>
      </div>

      <div class="loading">
        <van-loading v-show="myRoomLoading"
                     type="spinner"
                     size="65" />
      </div>
      <div v-show="!myRoomLoading"
           class="box">
        <div class="card"
             v-if="Object.keys(chatInfo).length > 0"
             @click="apply">
          <div class="roomName">{{ chatInfo.name }}</div>
          <div class="imgList">
            <div class="item"
                 v-for="(item, index) in chatInfo.members"
                 :key="index">
              <img v-if="Object.keys(item).length > 0"
                   :src="item.avatar"
                   alt="">
              <img v-else
                   src="@/assets/bracelet_img/71.jpg"
                   alt="">
              <div v-if="Object.keys(item).length > 0"
                   class="stamp">{{ item.level_grade }}</div>
            </div>
          </div>
          <div v-if="chatInfo.state == 0" class="stop stop-not">
            <img src="@/assets/bracelet_img/39.png" alt="">
            <span :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('准备中')}}</span>
          </div>
          <div v-if="chatInfo.state == 1" class="stop stop-conduct">
            <img src="@/assets/bracelet_img/39.png" alt="">
            <span :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('进行中')}}</span>
          </div>
          <div v-if="chatInfo.state == 2" class="stop stop-evaluate">
            <img src="@/assets/bracelet_img/39.png" alt="">
            <span :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('待评价')}}</span>
          </div>
          <div v-if="chatInfo.state == 3" class="stop stop-bg">
            <img src="@/assets/bracelet_img/39.png" alt="">
            <span :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('待结算')}}</span>
          </div>
          <div v-if="chatInfo.state == 4" class="stop stop-end">
            <img src="@/assets/bracelet_img/39.png" alt="">
            <span :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('已结束')}}</span>
          </div>
        </div>
        <div v-else
             class="card">
          <van-empty image="error"
                     image-size="100"
                     :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
                     :description="$t('暂未创建或加入聊天室')" />
        </div>
      </div>
    </div>

    <div v-else class="content">
      <!-- <div class="recommend">
        <span :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('推荐聊天室')}}</span>
      </div> -->
      <div class="my-room-top">
        <div class="myRoomTitle"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('推荐聊天室')}}</div>
        <div class="my-top-key"> 
          <img src="@/assets/bracelet_img/key.png" alt=""> X {{ Number(userInfo.free_key) + Number(userInfo.pay_key) }} 
          <van-button :loading="regainLoading"
                      :loading-text="$t('购买中...')"
                      @click="showNumKeys = true"
                      v-if="authorShow"
                      class="my-key-class"
                      :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('购买钥匙')}}</van-button>
          <van-button class="my-key-class"
                      v-else
                      @click="author"
                      :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('购买钥匙授权')}}</van-button>
        </div>
      </div>
      <van-list class="list"
                v-model="recommendLoading"
                :finished="recommendFinished"
                :finished-text="$t('没有更多了')"
                :loading-text="$t('加载中...')"
                :immediate-check="false"
                :offset="20"
                :class="{'vn-italic': $i18n.locale == 'vi'}"
                @load="getRecommendChat"
                v-if="recommendList.length > 0 && Object.keys(chatInfo).length == 0">
        <div class="item" v-for="(item, index) in recommendList" :key="index">
          <div class="right">
            <div class="img">
              <img :src="item.avatar" :alt="$t('头像')">
              <div class="text">{{ item.level_grade }}</div>
            </div>
            <div class="info">
              <div class="name" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{ item.nickname || $t('暂无昵称') }}</div>
              <div class="player" :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('聊天室')}} : {{ item.name || $t('这个聊天室没有名称') }} </div>
              <div class="player" :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('玩家')}} : {{ item.now_count }} / {{ item.max_count }}</div>
            </div>
          </div>
          <van-button class="left"
                      :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
                      :loading="item.applyLoading"
                      @click="applyFor(item.id, index)">{{$t('申请')}}</van-button>
        </div>
      </van-list>
      <div v-else-if="recommendList.length == 0 && Object.keys(chatInfo).length == 0">
        <van-empty image="error"
                   :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
                   :description="$t('暂无')" />
      </div>
      <div v-else>
        <van-empty image="error"
                   :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
                   :description="$t('已加入聊天室')" />
      </div>
    </div>

    <!-- <div @click="refresh" class="refresh" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('刷新')}}</div> -->
    <div class="btnBox">
      <van-button @click="refresh" class="refresh" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('刷新')}}</van-button>
    </div>
    
    <van-dialog v-model="showNumKeys"
                style="color:#000;" 
                :confirm-button-text="$t('确定')"
                :cancel-button-text="$t('取消')" 
                :title="$t('选择钥匙数量')"
                :beforeClose="setNum"
                show-cancel-button>
      <van-field name="stepper" :label="$t('数量')">
        <template #input>
          <van-stepper v-model="stepper" />
        </template>
      </van-field>
    </van-dialog>
  </div>
</template>
<script>
import { RecommendChatApi, ApplyApi, orderCreate, coinByType, nftRelatedPriceApi } from '@/api/api'
import { mapState } from 'vuex'
import { erc20Abi } from '@/utils/abi'

export default {
  props: {
    chatInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    userInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    myRoomLoading: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data () {
    return {
      recommendFrom: {
        page: 1,
        limit: 10
      },
      recommendLoading: false,
      recommendFinished: false,
      recommendList: [],
      regainLoading: false,
      tokenName: "SOLARIX",// 购买钥匙币种
      showNumKeys: false,
      stepper: 1, // 钥匙数量
      authorShow: false,
      tokenContract: null,// 币种合约
    }
  },
  mounted () {

  },
  computed: {
    ...mapState([
      'web3', 'placeOrderContract','placeOrderContractAddress'
    ]),
  },
  created () {
    this.waitForGlobal();
  },
  mounted () {

  },
  methods: {
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress')) {
        window.clearTimeout(this.timer);
        this.refresh()
        this.isAllowance()
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
    setNum(action, done){
      if (action == 'confirm') {
        // this.bugKey(this.stepper)
        this.getPaymentNumber(this.stepper)
        done()
      } else {
        done()
      }
    },
    // 显示支付数量
    getPaymentNumber(number){
      nftRelatedPriceApi({
        type: 0, //	类型 0：购买钥匙 1：购买评分 2:修复耐久 3:升级NFT
        number
      }).then(res => {
        if(res.code == 0){
          this.$dialog.confirm({
            title: this.$t('确认购买'),
            message: `${this.$t('购买钥匙当次所需数量为:')}${res.data.amount} ${this.tokenName}`,
            confirmButtonText: this.$t('确定'),
            cancelButtonText: this.$t('取消')
          }).then(() => {
            this.bugKey(number)
          }).catch(() => {});
        }
      })
    },
    //打开弹窗
    apply () {
      switch (this.chatInfo.state) {
        case '0':
          // 打开聊天室弹窗
          this.$emit('openRoom');
          break;
        case '1':
          // 打开挖矿弹窗
          this.$emit('openStake');
          break;
        case '2':
          // 跳转到评分页面
          if (this.chatInfo.is_score == 1) {
            this.$toast(this.$t('等待其他玩家评价'));
          } else {
            this.$router.push({
              path: '/roomScore'
            })
          }

          break;
      }

    },
    /* 申请 */
    applyFor (chat_id, index) {
      this.recommendList[index].applyLoading = true
      ApplyApi({ chat_id }).then(res => {
        this.recommendList[index].applyLoading = false
        this.$toast(res.msg);
        if (res.code == 0) {
          this.refresh();
        }
      })
    },
    refresh () {
      if (!this.recommendLoading) {
        this.$emit('getChatInfo');
        this.$emit('getUserInfo');
        this.recommendFrom.page = 1
        this.recommendList = []
        this.recommendLoading = true
        this.recommendFinished = false
        this.getRecommendChat();
      }
    },
    /* 获取推荐房间列表 */
    getRecommendChat () {
      this.recommendLoading = true
      RecommendChatApi(this.recommendFrom).then(res => {
        if (res.code == 0) {
          let chatList = res.data.list
          for (let index = 0; index < chatList.length; index++) {
            const element = chatList[index];
            element.applyLoading = false
          }
          if (chatList.length < this.recommendFrom.limit) {
            this.recommendFinished = true;
          }
          this.recommendLoading = false
          this.recommendList = this.recommendList.concat(chatList)
          this.recommendFrom.page++
        }
      })
    },
    /* 查询购买钥匙是否需要授权 */
    isAllowance () {
      coinByType({
        type: 0, //  0:购买钥匙  1:购买评分  2:修复耐久  3:升级NFT
      }).then(res => {
        if(res.code == 0){
          this.tokenName = res.data.name
          this.tokenContract = new this.web3.eth.Contract(erc20Abi, res.data.token);
          this.tokenContract.methods.allowance(localStorage.getItem('defaultAddress'), this.placeOrderContractAddress).call({ from: localStorage.getItem('defaultAddress') }, (error, result) => {
            if (!error) {
              if (result == 0) {
                //需要授权
                this.authorShow = false
              } else {
                //不需要授权 
                this.authorShow = true
              }
            } else {
              console.log(error);
            }
          });
        }
      })
    },
    //购买钥匙授权
    author () {
      if(!this.tokenContract){
        this.$toast(this.$t('网络不好,请稍后再试'));
        return
      }
      let toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('授权中...'),
      });
      this.tokenContract.methods.approve(
        this.placeOrderContractAddress,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      ).send({ from: localStorage.getItem('defaultAddress') }).then(() => {
        toast.clear();
        this.$toast(this.$t('授权成功'));//授权成功
        this.authorShow = true
      }).catch(() => {
        toast.clear();
        this.$toast(this.$t('授权失败'));//授权失败
        this.authorShow = false
      })
    },
    /* 购买钥匙 */
    bugKey (number) {
      let toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('购买中...'), //购买中...
      });
      this.regainLoading = true
      orderCreate({
        type: 0, //  0:购买钥匙  1:购买评分  2:修复耐久  3:升级NFT
        number
      }).then(res => {
        if (res.code == 0) {
          let amount = new this.$Bignumber(res.data.bsc_amount);
          this.placeOrderContract.methods.deposit(
            res.data.coin_token,
            this.web3.utils.fromDecimal(amount),
            res.data.type,
            res.data.id,
            res.data.sign_out_time,
            res.data.v,
            res.data.r,
            res.data.s
          ).send({ from: localStorage.getItem('defaultAddress') }).then(() => {
            this.regainLoading = false
            toast.clear();
            this.$toast(this.$t('购买成功,等待交易查询中'));
          })
          .catch(() => {
            this.regainLoading = false
            toast.clear();
            this.$toast(this.$t('购买失败'));
          })
        } else {
          this.regainLoading = false
          toast.clear();
          this.$toast(res.msg);
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-button--normal {
  padding: 0 10px;
}
/deep/ .van-button__text {
  color: #fff;
}
.joinRoom {
  div {
    box-sizing: border-box;
  }
  .myRoom {
    padding-left: 15px;
    .loading {
      display: flex;
      justify-content: center;
    }
    .my-room-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 11.25px;
      .myRoomTitle {
        font-family: '071-SSRuiFengTi';
        font-size: 24px;
      }
      .my-top-key {
        display: flex;
        align-items: center;
        justify-content: center;
        .my-key-class {
          height: 30px;
          margin-left: 10px;
          background-color: #5c65cb;
          border: 0;
        }
        img {
          width: 25px;
          height: 25px;
        }
      }
    }

    .box {
      padding-left: 11.25px;
      .card {
        min-height: 161.25px;
        background-color: #ffffff;
        border-radius: 7.5px 0 0 7.5px;
        padding-top: 11.25px;
        position: relative;
        .roomName {
          font-family: 'TsangerYuYangT-W05';
          font-size: 15px;
          color: #2b2b2b;
          padding: 7.5px 0 3px 3px;
          width: 50%;
          background-image: linear-gradient(115deg, #8ff9f6 0%, #ffffff 100%),
            linear-gradient(#2b2b2b, #2b2b2b);
          background-blend-mode: normal, normal;
          margin-bottom: 15px;
        }
        .imgList {
          padding-left: 7.5px;
          display: flex;
          flex-wrap: wrap;
          width: 262.5px;
          .item {
            width: 41.25px;
            height: 41.25px;
            border-radius: 50%;
            border: 2px solid #2b2b2b;
            position: relative;
            margin-right: 18.75px;
            margin-bottom: 11.25px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .stamp {
              width: 18.75px;
              height: 18.75px;
              line-height: 18.75px;
              text-align: center;
              border-radius: 50%;
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 10px;
              color: #ffffff;
              background-color: #2b2b2b;
              position: absolute;
              bottom: -3.75px;
              right: -7.5px;
            }
          }
        }
        .stop {
          position: absolute;
          right: 0;
          top: 0;
          display: flex;
          align-items: center;
          padding: 3.75px 7.5px 3.75px 15px;
          border-radius: 3.75px 0px 0px 18.75px;
          font-family: 'TsangerYuYangT-W04';
          font-size: 15px;
          img {
            width: 15px;
            height: 15px;
            margin-right: 3.75px;
          }
        }
        .stop-not {
          background-color: #b6b6b6;
        }
        .stop-conduct {
          background-color: #20b328;
        }
        .stop-evaluate {
          background-color: #dde01c;
        }
        .stop-bg {
          background-color: #d50505;
        }
        .stop-end {
          background-color: #333333;
        }
      }
    }
  }
  .content {
    padding: 22.5px 15px 0;
    .my-room-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 11.25px;
      .myRoomTitle {
        font-family: '071-SSRuiFengTi';
        font-size: 24px;
      }
      .my-top-key {
        display: flex;
        align-items: center;
        justify-content: center;
        .my-key-class {
          height: 30px;
          margin-left: 10px;
          background-color: #5c65cb;
          border: 0;
        }
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
    .loading {
      display: flex;
      justify-content: center;
    }
    .recommend {
      font-family: '071-SSRuiFengTi';
      font-size: 26px;
      margin-bottom: 11.25px;
    }
    .list {
      // height: 19vh;
      height: 50vh;
      overflow: auto;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10 */
      .item {
        width: 100%;
        height: 75px;
        background-image: linear-gradient(
            237deg,
            rgba(118, 233, 214, 0.83) 0%,
            rgba(159, 174, 230, 0.83) 100%
          ),
          linear-gradient(#ffffff, #ffffff);
        background-blend-mode: normal, normal;
        border-radius: 7.5px;
        // opacity: 0.75;
        padding: 7.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3.75px;
        .right {
          display: flex;
          align-items: center;
          .img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: #2b2b2b;
            border: solid 2px #2b2b2b;
            position: relative;
            margin-right: 22.5px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .text {
              width: 26.25px;
              height: 26.25px;
              line-height: 26.25px;
              background-color: #2b2b2b;
              border-radius: 50%;
              position: absolute;
              right: -13px;
              bottom: 0;
              text-align: center;
              font-family: 'AeroMaticsDisplayBoldItalic';
            }
          }
          .info {
            .name {
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 22.5px;
            }
            .player {
              font-family: 'TsangerYuYangT-W03';
              font-size: 11.25px;
            }
          }
        }
        .left {
          min-width: 86px;
          background-color: #5c65cb;
          border-radius: 11.25px 3px 11.25px 3px;
          border: 2px solid rgba(73, 98, 102, 0.5);
          padding: 0px 18px;
          height: 35px;
          font-family: 'REEJI-PinboGB-Flash';
          font-size: 16px;
          color: #2b2b2b;
          white-space:nowrap;
        }
      }
    }
    .list::-webkit-scrollbar {
      display: none;
    }
  }
  .btnBox{
    display: flex;
    justify-content: center;
  }
  .refresh {
    width: 198px;
    margin: 15px auto 0;
    background-color: #40e9ec;
    border-radius: 15px 3px 15px 3px;
    border: 2px solid rgba(0, 0, 0, 0.85);
    padding: 2px 0 3px 0;
    text-align: center;
    font-family: 'REEJI-PinboGB-Flash';
    font-size: 20px;
    color: #2b2b2b;
    /deep/ .van-button__text {
      color: #2b2b2b;
    }
  }

  .van-dialog{
    /deep/.van-dialog__confirm{
      .van-button__text{
        color: #ee0a24;
      }
    }
    /deep/.van-dialog__cancel{
      .van-button__text{
        color: #000;
      }
    }
    /deep/.van-field__control{
      justify-content: flex-end;
    }
  }
}
</style>