<template>
  <div class="room">
    <van-popup v-model="show"
               :overlay-style="{backgroundColor: 'rgba(0,0,0,0)'}"
               :close-on-click-overlay="false">
      <img @click="close"
           class="close"
           src="@/assets/bracelet_img/40.png"
           alt="">
      <div class="main">
        <div class="roomTitle"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{chatInfo.name}}</div>
        <div class="subTitle"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}">- {{chatInfo.count}} / {{chatInfo.max_count}} {{$t('准备')}} -</div>
        <!-- <div class="img"></div> -->
        <div class="imgList">
          <div class="item"
               v-for="(item, index) in chatInfo.members"
               :key="index">
            <div class="itemBox">
              <div class="avatar">
                <img class="img"
                     v-if="Object.keys(item).length > 0"
                     :src="item.avatar"
                     alt="">
                <img class="img"
                     v-else
                     src="@/assets/bracelet_img/71.jpg"
                     alt="">
                <div class="stamp"
                     v-if="Object.keys(item).length > 0">{{ item.level_grade }}</div>
                <img class="kick"
                     @click="kickFun(item.user_id)"
                     v-if="userInfo.id == chatInfo.user_id && userInfo.id !== item.user_id  && Object.keys(item).length > 0 "
                     src="@/assets/bracelet_img/70.png"
                     alt="">
              </div>
              <div class="name"
                   :class="{'vn-italic': $i18n.locale == 'vi'}"
                   v-if="item.user_id">ID:{{ item.user_id || ''}}</div>
              <div class="name"
                   :class="{'vn-italic': $i18n.locale == 'vi'}">{{ item.nickname }}</div>
              <div class="distance"
                   v-if="Object.keys(item).length > 0 && item.distance != -1">({{ item.distance }}M)</div>
            </div>
          </div>
        </div>
        <van-button class="btn startMining"
                    v-if="userInfo.id == chatInfo.user_id"
                    :loading="startLoading"
                    :class="{'vn-italic': $i18n.locale == 'vi'}"
                    @click="startMining(1)">{{$t('开始挖矿')}}</van-button>
        <van-button class="btn available_stop"
                    v-if="userInfo.id != chatInfo.user_id"
                    @click="out"
                    :class="{'vn-italic': $i18n.locale == 'vi'}"
                    :loading="quitLoading">{{$t('退出房间')}}</van-button>
        <!-- <div class="btn qrCode" @click="qrCode">二维码</div> -->
        <div :data-clipboard-text="chatInfo.url"
             @click="copyUrl"
             class="btn copyUrl pointer"
             :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('复制链接')}}</div>
        <!-- <div @click="goTo('/groupChat')"
             class="btn joinRoom pointer"
             :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('进入聊天室')}}</div> -->
        <div @click="sendInvite"
             class="btn sendInvite pointer"
             :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('发送邀请')}}</div>
        <div @click="delRoom"
             v-if="userInfo.id == chatInfo.user_id"
             class="btn del pointer"
             :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('解散房间')}}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { StartMiningApi, isCanOutApi } from '@/api/api'
import { dismissGroup, quitGroup, deleteGroupMember } from '@/utils/tim.js'
export default {
  data () {
    return {
      show: false,
      startLoading: false,
      quitLoading: false,
    }
  },
  props: {
    roomShow: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    chatInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    userInfo: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    roomShow () {
      this.show = this.roomShow
    }
  },
  created () {

  },
  methods: {
    goTo (url) {
      this.$router.push({
        path: url,
        query: {
          groupId: this.chatInfo.im_group_id,
          groupName: this.chatInfo.name
        }
      })
    },
    copyUrl () {
      var clipboard = new this.$Clipboard(".copyUrl");
      clipboard.on("success", (e) => {
        this.$toast(this.$t('复制成功'));
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$toast(this.$t('不支持复制'));
        // 释放内存
        clipboard.destroy();
      });
    },
    sendInvite () {
      this.$emit('roomClose', 'openInviteFriend');
    },
    /* 房主解散房间 */
    delRoom () {
      let that = this
      that.$dialog.confirm({
        title: that.$t('提示'),
        message: that.$t('此操作会将房间解散，并且不会退还钥匙'),
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
      }).then(() => {
        dismissGroup(this.chatInfo.im_group_id).then((res) => {
          // 解散成功
          if (res.code == 0) {
            this.close()
            this.$emit('getChatInfo')
            this.$toast(this.$t('成功'));
          }
        }).catch(err => {
          this.$toast(err);
        });
      }).catch(() => {
        // on cancel
      });

    },
    /* 开始挖矿 */
    startMining (status) {
      this.startLoading = true
      StartMiningApi().then(res => {
        if (res.code == 0) {
          this.$emit('getChatInfo')
          let ti = setTimeout(() => {
            clearTimeout(ti)
            this.$emit('diggingOre', status)
            this.$emit('roomClose', 'startStake');
          }, 500)
        } else {
          this.$toast(res.msg);
        }
        this.startLoading = false
      })
    },
    /* 踢人 */
    kickFun(id){
      this.$dialog.confirm({
        title: this.$t('提示'),
        message: this.$t('请确认是否将此用户请离房间'),
        confirmButtonText:this.$t('确定'),
        cancelButtonText:this.$t('取消'),
      }).then(() => {
        deleteGroupMember(this.chatInfo.im_group_id, id).then(res => {
          // 删除后的群组信息 res.data.group
          // 被删除的群成员的 userID 列表 res.data.userIDList
          if (res.code == 0) {
            this.$toast(this.$t('成功'));
          }
        }).catch(err => {
          this.$toast(err);
        });
      })
      .catch(() => {
        // on cancel
      });
    },
    /* 退出房间 */
    out () {
      this.$dialog.confirm({
        title: this.$t('提示'),
        message: this.$t('请确认是否退出聊天室'),
        confirmButtonText:this.$t('确定'),
        cancelButtonText:this.$t('取消'),
      }).then(() => {
        isCanOutApi({ chat_id: this.chatInfo.id }).then(resu => {
          if(resu.code == 0){
            quitGroup(this.chatInfo.im_group_id).then((res) => {
              if (res.code == 0) {
                let timer = setTimeout(() => {
                  clearTimeout(timer)
                  this.$emit('getChatInfo')
                }, 500)
                this.close()
                this.$toast(this.$t('成功'));
              }
            }).catch(err => {
              this.$toast(err);
            });
          }else{
            this.$toast(resu.msg);
          }
        })
      })
      .catch(() => {
        // on cancel
      });

    },
    close () {
      this.$emit('roomClose', 'close');
    },
    qrCode () {
      this.$emit('roomClose', 'openQrCode');
    },
  }
}
</script>

<style lang="less" scoped>
.room {
  .van-popup {
    width: 82%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 7.5px;
    .close {
      width: 22.5px;
      height: 22.5px;
      position: absolute;
      right: 7.5px;
      top: 7.5px;
    }
  }
  .main {
    div {
      box-sizing: border-box;
    }
    padding: 26.25px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .roomTitle {
      font-family: 'TsangerYuYangT-W05';
      font-size: 30px;
    }
    .subTitle {
      font-family: 'TsangerYuYangT-W05';
      font-size: 12px;
      margin-bottom: 22.5px;
    }
    .img {
      width: 236.25px;
      height: 236.25px;
      background: #fff;
      margin-bottom: 18.75px;
    }
    .imgList {
      // padding-left: 7.5px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 264.5px;
      margin-bottom: 41.25px;
      .item {
        margin-right: 18.75px;
        margin-bottom: 11.25px;
        .itemBox {
          display: flex;
          flex-direction: column;
          align-items: center;
          .avatar {
            width: 41.25px;
            height: 41.25px;
            border-radius: 50%;
            border: 3px solid #2b2b2b;
            position: relative;
            .img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .stamp {
              width: 18.75px;
              height: 18.75px;
              line-height: 18.75px;
              text-align: center;
              border-radius: 50%;
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 10px;
              color: #ffffff;
              background-color: #2b2b2b;
              position: absolute;
              bottom: -3px;
              right: -7.5px;
            }
            .kick {
              width: 13px;
              height: 13px;
              position: absolute;
              right: -6px;
              top: -4px;
              cursor: pointer;
            }
          }
          .name {
            max-width: 50px;
            font-family: 'AeroMaticsDisplayRegular';
            font-size: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
          }
          .distance {
            font-family: 'AeroMaticsDisplayRegular';
            font-size: 10px;
          }
        }
        .nullBox {
          cursor: pointer;
          .avatar {
            width: 41.25px;
            height: 41.25px;
            border-radius: 50%;
            border: 3px solid #2b2b2b;
            position: relative;
            .img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .stamp {
              width: 18.75px;
              height: 18.75px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background-color: #2b2b2b;
              position: absolute;
              bottom: -3px;
              right: -7.5px;
              img {
                width: 10px;
                height: 11px;
              }
            }
          }
        }
      }
      .item:nth-child(4n) {
        margin-right: 0px;
      }
    }
    .btn {
      border-radius: 15px 3px 15px 3px;
      border: 2px solid rgba(0, 0, 0, 0.5);
      padding: 3px 0 7.5px 0;
      font-family: 'REEJI-PinboGB-Flash';
      font-size: 20px;
      color: #2b2b2b;
      width: 100%;
      text-align: center;
    }
    .available_stop {
      background-color: #e0220b;
      color: #fff;
      margin-bottom: 15px;
    }
    .startMining {
      background-color: #a7f264;
      margin-bottom: 15px;
    }
    .qrCode {
      background-color: #5c65cb;
    }
    .copyUrl {
      background-color: #a7f264;
      margin-bottom: 15px;
    }
    .sendInvite {
      background-color: #5c65cb;
    }
    .joinRoom{
      background-color: #5c65cb;
      margin-bottom: 15px;
    }
    .del {
      background-color: #d31111;
      color: #fff;
      margin-top: 20px;
    }
  }
}
@media screen and (min-width: 480px) {
  //在这里写非小屏幕设备的样式  -- 非手机
  .room {
    .van-popup {
      width: 350px;
    }
  }
}
</style>
