<template>
  <div class="scan">
    <!-- <div class="card">
            <img src="@/assets/bracelet_img/41.png" alt="">
        </div> -->

    <!-- <p class="error">错误信息: {{ error }}</p>
        <p class="decode-result">扫描结果: <b>{{ result }}</b></p>
        <qrcode-stream @decode="onDecode" @init="onInit" v-show="qrcode" :camera="camera" :torch="torchActive"/> -->

    <van-field class="url"
               v-model="url"
               label-width="0"
               input-align="center"
               :class="{'vn-italic': $i18n.locale == 'vi'}"
               :placeholder="$t('请输入邀请链接')" />
    <!-- <div class="btn" @click="joinRoom">加入聊天室</div> -->
    <van-button class="btn"
                :loading="joinRoomLoading"
                :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
                @click="joinRoom">{{$t('加入聊天室')}}</van-button>
  </div>
</template>

<script>
import { AddUrlRoomApi } from '@/api/api'
import { QrcodeStream } from 'vue-qrcode-reader';
export default {
  components: { QrcodeStream },
  data () {
    return {
      url: '',

      result: '', // 扫码结果信息
      error: '', // 错误信息
      qrcode: true,
      torchActive: false,
      camera: 'auto',
      joinRoomLoading: false,
    }
  },
  mounted () {

  },
  methods: {
    onDecode (result) {
      alert(result);
      this.result = result
    },
    async onInit (promise) {
      console.log('有执行嘛----', promise);
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: 您需要授予相机访问权限"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: 这个设备上没有摄像头"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: 所需的安全上下文(HTTPS、本地主机)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: 相机被占用"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: 安装摄像头不合适"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: 此浏览器不支持流API"
        }
      }
    },
    /* 通过链接加入聊天室 */
    joinRoom () {
      this.joinRoomLoading = true
      var arr = this.url.split('?')
      var newArr = arr[1].split('=')
      if (newArr[0] == 'id') {
        var room_invite = newArr[1]
        AddUrlRoomApi({ room_invite }).then(res => {
          this.$emit("getChatInfo");
          this.$emit('choice', 'joinRoom')
          this.joinRoomLoading = false;
          this.$toast(res.msg);
        })
      }
    },
  }
}
</script>

<style lang="less" scoped>
.scan {
  // div{
  //     box-sizing: border-box;
  // }
  padding: 0 33.75px;
  .card {
    width: 100%;
    height: 315px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 7.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 7.5px;
    img {
      width: 45px;
      height: 37.5px;
    }
  }
  .van-field {
    background-color: #ffffff;
    border-radius: 18.75px;
  }
  .url {
    margin-bottom: 26.25px;
    /deep/.van-field__control {
      color: #2b2b2b;
      font-family: 'TsangerYuYangT-W04';
      font-size: 15px;
    }
    /* WebKit, Blink, Edge */
    /deep/.van-field__control::-webkit-input-placeholder {
      font-family: 'TsangerYuYangT-W04';
      font-size: 15px;
    }
    /* Mozilla Firefox 4 to 18 */
    /deep/.van-field__control:-moz-placeholder {
      font-family: 'TsangerYuYangT-W04';
      font-size: 15px;
    }
    /* Mozilla Firefox 19+ */
    /deep/.van-field__control::-moz-placeholder {
      font-family: 'TsangerYuYangT-W04';
      font-size: 15px;
    }
    /* Internet Explorer 10-11 */
    /deep/.van-field__control:-ms-input-placeholder {
      font-family: 'TsangerYuYangT-W04';
      font-size: 15px;
    }
    /* Microsoft Edge */
    /deep/.van-field__control::-ms-input-placeholder {
      font-family: 'TsangerYuYangT-W04';
      font-size: 15px;
    }
  }
  .btn {
    width: 240px;
    height: 48.75px;
    background-color: #a7f264;
    border-radius: 15px 3px 15px 3px;
    border: 2px solid rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'REEJI-PinboGB-Flash';
    font-size: 22.5px;
    color: #2b2b2b;
    margin: 0 auto 11.25px;
  }
}
</style>