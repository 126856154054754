<template>
  <div class="request">
    <van-popup v-model="show"
               :overlay-style="{ backgroundColor: 'rgba(0,0,0,0)' }"
               :close-on-click-overlay="false">
      <img @click="close"
           class="close"
           src="@/assets/bracelet_img/40.png"
           alt="" />
      <div class="main">
        <div class="roomTitle" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('聊天室邀请')}}</div>

        <!-- <div class="subTitle">- 06 / 08    准备 -</div> -->

        <div class="recommend" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('邀请')}} : {{ total }}</div>
        <van-list v-model="loading"
                  :finished="finished"
                  :class="{'vn-italic': $i18n.locale == 'vi'}"
                  :finished-text="$t('没有更多了')"
                  :loading-text="$t('加载中...')"
                  :immediate-check="false"
                  :offset="20"
                  @load="onLoad"
                  class="list">
          <div class="item" v-for="(item, index) in list" :key="index">
            <div class="right">
              <div class="img">
                <van-image class="image"
                           lazy-load
                           round
                           :src="item.chat_user_info.avatar" />
                <div class="text">{{ item?.chat_user_info?.level_grade ?? 0 }}</div>
              </div>
              <div class="info">
                <div class="name" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">
                  {{ item?.chat_user_info?.nickname || $t('暂无名称') }}
                </div>
                <div class="Ranking" :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('排行')}} : {{ item.chat_user_info.income_ionz_ranking == 0 ? '100+' : item.chat_user_info.income_ionz_ranking }}</div>
                <div v-show="item.user_to_chat_distance != -1" class="Ranking" :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('距离')}} : {{ item.user_to_chat_distance }} {{$t('米')}}</div>
              </div>
            </div>
            <div class="left">
              <van-button :loading="item.handleYesLoading" loading-size="27px" :icon="require('@/assets/bracelet_img/9.png')" @click="handle(item, 1)" class="mar_right" round />

              <van-button :loading="item.handleNoLoading" loading-size="27px" :icon="require('@/assets/bracelet_img/10.png')" @click="handle(item, 2)" round />
            </div>
          </div>
        </van-list>
        <!-- <div @click="refresh" class="btn" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('刷新')}}</div> -->
        <van-button @click="refresh" class="btn" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('刷新')}}</van-button>
      </div>
    </van-popup>
  </div>
</template>
 
<script>
import { ChatListApi, ChatOperationApi } from "@/api/api";
import { mapState } from "vuex";
export default {
  data () {
    return {
      show: false,
      total: 0,

      loading: false,
      finished: false,
      page: 1,
      limit:10,
      list: [],
      timer: null,
      handleLoading: false,
    };
  },
  props: {
    inviteShow: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  computed: {
    ...mapState(["web3"]),
  },
  watch: {
    inviteShow () {
      this.show = this.inviteShow;
      if (this.show) {
        if (!this.loading) {
          this.waitForGlobal();
        }
      }
    },
  },
  created () { },
  methods: {
    //同意或者拒绝加入房间
    handle (item, state) {
      if(state == 1){
        if(item.handleNoLoading){
          return
        }
        item.handleYesLoading = true
      }else{
        if(item.handleYesLoading){
          return
        }
        item.handleNoLoading = true
      }
      ChatOperationApi({ id: item.id, state }).then((res) => {
        if (res.code == 0) {
          this.refresh();
          this.$emit("getChatInfo");
          this.close();
        }
        this.$toast(res.msg);
        if(state == 1){
          item.handleYesLoading = false
        }else{
          item.handleNoLoading = false
        }
      });
    },
    onLoad () {
      ChatListApi({
        button_type: 1, // 列表需要审批的传1
        type: 1, // 类型  0：申请列表   1：邀请列表
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        if (res.code == 0) {
          let arr = res.data.list
          arr = arr.map((item) => {
            return {
              ...item,
              handleYesLoading: false,
              handleNoLoading: false,
            }
          })
          this.list = this.list.concat(arr);
          this.loading = false;
          this.total = res.count;
          if (arr.length < this.limit) {
            this.finished = true;
          }
          this.page++;
        } else {
          this.finished = true;
        }
      });
    },
    refresh () {
      if (!this.loading) {
        this.loading = true;
        this.finished = false;
        this.list = [];
        this.page = 1;
        this.onLoad();
      }
    },
    close () {
      this.$emit("inviteClose");
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem("Token") && this.web3 && localStorage.getItem("defaultAddress")) {
        window.clearTimeout(this.timer);
        this.loading = true;
        this.finished = false;
        this.list = [];
        this.page = 1;
        this.onLoad();
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(this.waitForGlobal, 100);
      }
    },
  },
};
</script>
 
 <style lang="less" scoped>
.request {
  .van-popup {
    width: 82%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 7.5px;
    .close {
      width: 22.5px;
      height: 22.5px;
      position: absolute;
      right: 7.5px;
      top: 7.5px;
    }
  }
  .main {
    div {
      box-sizing: border-box;
    }
    padding: 18.75px 11.25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .roomTitle {
      font-family: 'TsangerYuYangT-W05';
      font-size: 30px;
      margin-bottom: 10px;
    }
    .subTitle {
      font-family: 'TsangerYuYangT-W05';
      font-size: 11.25px;
    }

    .recommend {
      width: 100%;
      font-family: '071-SSRuiFengTi';
      font-size: 30px;
      margin-bottom: 7.5px;
    }
    .list {
      width: 100%;
      height: 41.5vh;
      overflow: auto;

      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10 */
      .item {
        width: 100%;
        min-height: 60px;
        background-image: linear-gradient(
            237deg,
            rgba(118, 233, 214, 0.83) 0%,
            rgba(159, 174, 230, 0.83) 100%
          ),
          linear-gradient(#ffffff, #ffffff);
        background-blend-mode: normal, normal;
        border-radius: 7.5px;
        padding: 7.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3.75px;
        .right {
          display: flex;
          align-items: center;
          width: 76%;
          .img {
            width: 48.75px;
            height: 48.75px;
            border-radius: 50%;
            background-color: #2b2b2b;
            border: 2px solid #2b2b2b;
            position: relative;
            margin-right: 18.75px;
            .image {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .text {
              width: 22.5px;
              height: 22.5px;
              line-height: 22.5px;
              background-color: #2b2b2b;
              border-radius: 50%;
              position: absolute;
              right: -13px;
              bottom: 0;
              text-align: center;
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 12px;
            }
          }
          .info {
            width: calc(100% - 68px);
            .name {
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 18.75px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .Ranking {
              font-family: 'TsangerYuYangT-W03';
              font-size: 12px;
            }
          }
        }
        .left {
          display: flex;
          align-items: center;
          // justify-content: center;
          img {
            width: 26.25px;
            height: 26.25px;
            border-radius: 50%;
          }
          .mar_right {
            margin-right: 11.25px;
          }
          /deep/.van-button{
            width: 26.25px;
            height: 26.25px;
            border-radius: 50%;
            padding: 0;
            border: none;
            background: transparent;
            color: #fff;
          }
        }
      }
    }
    .list::-webkit-scrollbar {
      display: none;
    }
    .btn {
      margin-top: 30px;
      background-color: #40e9ec;
      border-radius: 15px 3px 15px 3px;
      border: 2px solid rgba(0, 0, 0, 0.9);
      padding: 3px 75px;

      font-family: 'REEJI-PinboGB-Flash';
      font-size: 22.5px;
      color: #2b2b2b;
    }
  }
}
@media screen and (min-width: 480px) {
  //在这里写非小屏幕设备的样式  -- 非手机
  .request {
    .van-popup {
      width: 350px;
    }
  }
}
</style>