<template>
  <div class="roomHome">
    <watch-head :title="$t('社交聊天室')"
                :img="require('@/assets/bracelet_img/38.png')" />
    <div class="qiehuan">
      <div class="tab">
        <div class="item"
             :class="{'act': tabType == 'joinRoom', 'vn-bold-italic': $i18n.locale == 'vi'}"
             @click="choice('joinRoom')">{{$t('加入聊天室')}}</div>
        <div class="item"
             :class="{'act': tabType == 'createRoom', 'vn-bold-italic': $i18n.locale == 'vi'}"
             @click="choice('createRoom')">{{$t('创建聊天室')}}</div>
        <div class="item"
             :class="{'act': tabType == 'scan', 'vn-bold-italic': $i18n.locale == 'vi'}"
             @click="choice('scan')">{{$t('邀请链接')}}</div>
      </div>
    </div>

    <join-room v-show="tabType == 'joinRoom'"
               :chatInfo="chatInfo"
               :userInfo="userInfo"
               :myRoomLoading="myRoomLoading"
               @openStake="openStake"
               @getChatInfo="getChatInfo"
               @openRoom="openRoom" />
    <create-room v-show="tabType == 'createRoom'"
                 @getUserInfo="getUserInfo"
                 @choice="choice"
                 @getChatInfo="getChatInfo" />
    <scan v-show="tabType == 'scan'"
          @choice="choice"
          @getChatInfo="getChatInfo" />

    <div class="btm">
      <div class="left">
        <div @click="gotoUnityweb"
             class="box">
          <img src="@/assets/bracelet_img/5.png" alt="">
        </div>
      </div>
      <div class="right">
        <van-badge :dot="isRequest">
          <div @click="openRequest"
          :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
               class="ask">{{$t('请求')}}</div>
        </van-badge>
        <van-badge :dot="isInvite">
          <div @click="openInvite"
          :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
               class="invite">{{$t('邀请')}}</div>
        </van-badge>

      </div>
    </div>

    <qr-code :qrCodeShow="qrCodeShow"
             @qrCodeClose="qrCodeClose" />
    <room :roomShow="roomShow"
          :chatInfo="chatInfo"
          :userInfo="userInfo"
          @getUserInfo="getUserInfo"
          @getChatInfo="getChatInfo"
          @diggingOre="diggingOre"
          @roomClose="roomClose" />
    <invite-friends :inviteFriendShow="inviteFriendShow"
                    :chatInfo="chatInfo"
                    @inviteFriendClose="inviteFriendClose" />
    <request :requestShow="requestShow"
             :chatInfo="chatInfo"
             @getChatInfo="getChatInfo"
             @requestClose="requestClose" />
    <invite :inviteShow="inviteShow"
            @getChatInfo="getChatInfo"
            @inviteClose="inviteClose" />
    <stake :stakeShow="stakeShow"
           :chatInfo="chatInfo"
           :userInfo="userInfo"
           @diggingOre="diggingOre"
           @getChatInfo="getChatInfo"
           @stakeClose="stakeClose" />
  </div>
</template>

<script>
import watchHead from '@/components/watchHead/index.vue'
import joinRoom from './components/joinRoom/index.vue'
import createRoom from './components/createRoom/index.vue'
import scan from './components/scan/index.vue'
import qrCode from './components/room/qrCode.vue'
import room from './components/room/room.vue'
import inviteFriends from './components/inviteFriends/inviteFriends.vue'
import request from './components/request/index.vue'
import invite from './components/invite/index.vue'
import stake from './components/stake/index.vue'
import { mapState } from 'vuex'
import { userInfo, ChatInfoApi, AddUrlRoomApi } from '@/api/api'
import { socialRoom } from '@/utils/tim.js'

export default {
  components: {
    joinRoom,
    createRoom,
    scan,
    qrCode,
    room,
    inviteFriends,
    request,
    stake,
    invite,
    watchHead
  },
  data () {
    return {
      tabType: 'joinRoom',
      qrCodeShow: false,
      roomShow: false,
      inviteFriendShow: false,
      requestShow: false,
      inviteShow: false,
      stakeShow: false,
      myRoomLoading: false,
      chatInfo: {},
      userInfo: {},
      isRequestOrisInvite:[false,false],// 请求和邀请按钮是否显示红色小点
      isRequest: false,
      isInvite: false,
      WebSocketLoad: null,
      isUrlJoin: true,//判断是否能通过URL加入房间
    }
  },
  mounted () {
    socialRoom((data) => {
      this.monitor(data)
      // this.monitorRoom(data)
    })

    // 添加监听事件 (当前标签页隐藏和显示（标签页切换）) 进入后台或者从后台打开页面
    document.addEventListener("visibilitychange", this.handleVisiable);
  },
  beforeDestroy () {
    document.removeEventListener('visibilitychange', this.handleVisiable);
  },
  created () {
    this.waitForGlobal();
  },
  computed: {
    ...mapState([
      'web3',
    ]),
  },

  methods: {
    handleVisiable (e) {
      if (e.target.visibilityState != "visible") { // 当前标签页隐藏时执行
        if(this.$route.path == '/roomHome'){
          console.log("离开当前标签页");
          this.stakeClose();
          this.getChatInfo()
        }
      }
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress') && localStorage.getItem('userInfo')) {
        window.clearTimeout(this.timer);
        this.getUserInfo()
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },

    /* 获取当前用户信息 */
    getUserInfo () {
      userInfo({}, localStorage.getItem('Token')).then(res => {
        if (res.code == 0) {
          this.userInfo = res.data
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          /* 用户直接通过链接进入页面加入聊天室 */
          if (this.$route.query.id) {
            // if(this.isUrlJoin){//重连之后禁止调用接口加入房间
              this.urlJoin(this.$route.query.id)
            // }
          }else{
            this.getChatInfo()
          }
        }
      })

    },
    /* 用户直接通过链接进入页面加入聊天室 */
    urlJoin (room_invite) {
      AddUrlRoomApi({ room_invite }).then(res => {
        // if(res.code == 0){
        //   this.isUrlJoin = false //进入房间后就不允许再次通过邀请链接加入
        // }
        this.$router.replace({ query: {} }); // 通过链接加入聊天室后清除地址栏参数
        this.getChatInfo()
        this.$toast(res.msg);
      })
    },
    /* 获取当前聊天室 */
    getChatInfo () {
      ChatInfoApi().then(res => {
        if (res.code == 0) {
          if(res.data){
            this.chatInfo = res.data
            this.chatInfo.count = this.chatInfo.members.length
            let nums = Number(this.chatInfo.max_count) - this.chatInfo.members.length
            for (let i = 0; i < nums; i++) {
              this.chatInfo.members.push({});
            }
            return
          }
        }
        this.chatInfo = {}
      })
    },

    /* 开始 1 or 结束 2 挖矿 调用 */
    diggingOre (state) {
      if (state == 2) {
        this.stakeClose()
        this.$router.push({
          path: '/roomScore'
        })
      }
    },
    monitorRoom(data){
      switch (data.code) {
        case 0:
          // 走提示
          this.setTime(data.title)
          break;
        case 1:
          // 走路由
          this.$router.push({
            path: data.route
          })
          break;
        case 2:
          // 走弹窗
          let ti = setTimeout(() => {
            clearTimeout(ti)
            this.roomClose(data.pop) // 再打开挖矿弹窗
          }, 200)
          break;
        case 3:
          // 调接口
          let timer = setTimeout(() => {
            clearTimeout(timer)
            this.getChatInfo()
          }, 500)
          break;
        case 4:
          // 显示按钮小点
          this.isRequestOrisInvite[data.exp.button_index] = true
          break;
      }
    },
    /* 监听webSocket状态码进行相应操作 */
    monitor (data) {
      switch (data.code) {
        case 100000:
          // xxx加入房间
          let tim = setTimeout(() => {
            clearTimeout(tim)
            this.getChatInfo()
          }, 500)
          break;
        case 100001:
          // xxx退出房间
          let time = setTimeout(() => {
            clearTimeout(time)
            this.getChatInfo()
          }, 500)
          break;
        case 100002:
          // 开始挖矿
          this.getChatInfo()
          let ti = setTimeout(() => {
            clearTimeout(ti)
            this.roomClose('startStake') // 再打开挖矿弹窗
          }, 200)

          break;
        case 100003:
          // 结束挖矿
          this.diggingOre(2) //结束挖矿
          break;
        case 100004:
          // 有人申请加入房间
          this.setTime(this.$t(data.title))
          this.isRequest = true
          break;
        case 100005:
          // 房主同意申请 or 邀请人同意加入房间
          this.getChatInfo()
          break;
        case 100006:
          // 收到房间邀请
          this.setTime(this.$t(data.title))
          this.isInvite = true
          break;
        case 100007:
          // 房间销毁通知
          this.getChatInfo()
          break;
        case 100008:
          // 房间状态改变
          this.getChatInfo()
          break;
        case 100009:
          // 房主拒绝申请----提示给申请的用户
          this.setTime(this.$t(data.title))
          this.getChatInfo()
          break;
        case 100010:
          // 用户拒绝加入----提示给房主
          this.setTime(this.$t(data.title))
          this.getChatInfo()
          break;
        case 100011:
          // 房间销毁通知
          this.getChatInfo()
          break;
      }
    },
    setTime (title) {
      let timer = setTimeout(() => {
        this.$toast(title);
        clearTimeout(timer)
      }, 200)
    },
    choice (type) {
     //  console.log(type)
      if (type != this.tabType) {
        this.getChatInfo()
      }
      this.tabType = type
    },
    //打开挖矿弹窗
    openStake () {
      this.stakeShow = true
    },
    //关闭挖矿弹窗
    stakeClose () {
      this.stakeShow = false
    },
    //打开邀请弹窗
    openInvite () {
      this.isInvite = false
      this.inviteShow = true
    },
    //关闭邀请弹窗
    inviteClose () {
      this.isInvite = false
      this.inviteShow = false
    },
    //打开请求弹窗
    openRequest () {
      this.isRequest = false
      this.requestShow = true
    },
    //关闭请求弹窗
    requestClose () {
      this.isRequest = false
      this.requestShow = false
    },
    //打开邀请好友弹窗
    openInviteFriend () {
      this.inviteFriendShow = true
    },
    //关闭邀请好友弹窗
    inviteFriendClose () {
      this.inviteFriendShow = false
    },
    //打开二维码弹窗
    openQrCode () {
      this.qrCodeShow = true
    },
    //关闭二维码弹窗
    qrCodeClose (value) {
      this.qrCodeShow = false
      if (value === 'openInviteFriend') {
        this.openInviteFriend();
      }
    },
    //打开聊天室弹窗
    openRoom () {
      this.roomShow = true
    },
    //关闭聊天室弹窗
    roomClose (value) {
      this.roomShow = false
      if (value === 'openQrCode') {
        this.openQrCode();
      }
      if (value === 'startStake') {
        this.openStake();
      }
      if (value === 'openInviteFriend') {
        this.openInviteFriend();
      }
    },
    gotoUnityweb () {
      window.location.href = 'https://api.metanebulas.com/unityweb/'
    },
  }
}
</script>

<style lang="less" scoped>
.roomHome {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 67.5px;
  background: url(~@/assets/bracelet_img/8.png) no-repeat;
  background-size: 100% 100%;
  color: #ffffff;
  .qiehuan {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 30px 0;
    .tab {
      width: 98%;
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 26.25px 0px 0px 3.75px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7.5px;
      .item {
        width: 33%;
        text-align: center;
        box-shadow: 0px 0px 0px 0px rgba(19, 19, 19, 0.16);
        border-radius: 18.75px 3.75px 18.75px 3.75px;
        font-family: '071-SSRuiFengTi';
        font-size: 18px;
        padding: 3.75px 0px;
        color: #5c65cb;
        cursor: pointer;
      }
      .act {
        background-color: #a7f264;
        color: #2b2b2b;
      }
    }
  }

  .btm {
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 22.5px;
    .left {
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 0 3px 3px 0;
      padding: 3px 3px 3px 0;
      margin-right: 3px;
      .box {
        padding: 3px 10px 3px 7.5px;
        background-color: rgba(43, 43, 43, 0.9);
        border-radius: 0 3px 3px 0;
        border: solid 2px #7736fe;
        border-left: none;
        display: flex;
        img {
          width: 26px;
          height: 18.75px;
        }
      }
    }
    .right {
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 3px 26.25px 3px 3px;
      padding: 3.75px 20px 3.75px 7.5px;
      display: flex;
      align-items: center;
      font-family: 'REEJI-PinboGB-Flash';
      font-size: 18.75px;
      color: #2b2b2b;
      .ask {
        background-color: #5c65cb;
        box-shadow: 0px 0px 0px 0px rgba(19, 19, 19, 0.16);
        border-radius: 18.75px 3px 18.75px 3px;
        padding: 2.5px 0;
        margin-right: 7.5px;
        width: 150px;
        text-align: center;
      }
      .invite {
        background-image: linear-gradient(#a7f264, #a7f264),
          linear-gradient(#5c65cb, #5c65cb);
        background-blend-mode: normal, normal;
        box-shadow: 0px 0px 0px 0px rgba(19, 19, 19, 0.16);
        border-radius: 18.75px 3px 18.75px 3px;
        padding: 2.5px 0;
        width: 150px;
        text-align: center;
      }
    }
  }
}
</style>
