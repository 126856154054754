<template>
  <div class="stake">
    <van-popup v-model="show"
               :overlay-style="{backgroundColor: 'rgba(0,0,0,0)'}"
               :close-on-click-overlay="false">
      <img @click="close"
           class="close"
           src="@/assets/bracelet_img/40.png"
           alt="">
      <div class="main">
        <div class="roomTitle"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{chatInfo.name}}</div>
        <div class="subTitle"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
             v-if="Object.keys(chatInfo).length > 0">- {{$t('在挖矿中')}} -</div>
        <div class="circle">
          <vue-ellipse-progress :size="250"
                                line="round"
                                :progress="progress"
                                lineMode="out -7%"
                                :color="color"
                                emptyColor="#BDAAB9"
                                emptyColorFill="#fff"
                                thickness="18"
                                emptyThickness="5">
            <template v-slot:legend-caption>
              <div slot="legend-caption"
                   class="represent"
                   :style="'border: 5px solid' + color + ';'">
                <div class="percen">{{ progress }}%</div>
                <div class="timeProcess" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">-{{$t('定时进程')}}-</div>
                <van-count-down :time="time" />
              </div>
            </template>
          </vue-ellipse-progress>
        </div>
        <div v-show="eyeShow" class="noRating">{{$t('*请在1小时结束社交后进行评分')}}</div>
        <van-button v-show="eyeShow && chatInfo.user_id == userInfo.id"
                    :loading="stopLoading"
                    class="btn mar_btm_4 pointer"
                    :class="{'available_stake': progress >= 100, 'disable_stake': progress < 100, 'vn-bold-italic': $i18n.locale == 'vi'}"
                    @click="goToScore(2)" >{{$t('完成挖矿')}}</van-button>
        <div v-show="eyeShow"
             v-if="progress < 100 && chatInfo.user_id !== userInfo.id"
             class="btn pointer"
             @click="out"
             :class="{'disable_stop': progress >= 100, 'available_stop': progress < 100, 'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('停止聊天室')}}</div>

        <div class="stakeBtm">
          <div v-show="eyeShow"
               @click="eyeShow = !eyeShow"
               class="eye">
            <img src="@/assets/bracelet_img/42.png"
                 alt="">
          </div>
          <div v-show="!eyeShow"
               @click="eyeShow = !eyeShow"
               class="eyeClose">
            <img src="@/assets/bracelet_img/43.png"
                 alt="">
          </div>

          <div v-show="!eyeShow"
               class="player">
            <div class="onlinePlayer" :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('在线玩家')}}</div>
            <div class="number"
                 v-if="Object.keys(chatInfo).length > 0">{{chatInfo.count}} / {{chatInfo.max_count}}</div>
            <div class="imgList">
              <div class="item"
                   v-for="(item, index) in chatInfo.members"
                   :key="index">
                <img v-if="Object.keys(item).length > 0"
                     :src="item.avatar"
                     alt="">
                <img v-else
                     src="@/assets/bracelet_img/71.jpg"
                     alt="">
                <div v-if="Object.keys(item).length > 0"
                     class="stamp">{{ item.level_grade }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { VueEllipseProgress } from "vue-ellipse-progress";
import { StartScoreApi, isCanOutApi, systemTimeApi } from '@/api/api'
import { quitGroup, getGroupProfile } from '@/utils/tim.js'
export default {
  components: { VueEllipseProgress },
  data () {
    return {
      eyeShow: true,
      show: false,
      time: 100 * 1000,
      progress: 0,
      timer: null,
      stopLoading: false,
      currentTime: 0,//当前服务器时间
    }
  },
  props: {
    stakeShow: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    chatInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    userInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },
  computed: {
    color () {
      if (this.progress <= 25) {
        return "#EF260C"
      } else if (this.progress > 25 && this.progress <= 50) {
        return "#F2A700"
      } else if (this.progress > 50 && this.progress <= 75) {
        return "#A6E50C"
      } else {
        return "#1FC116"
      }
    }
  },
  watch: {
    stakeShow () {
      this.show = this.stakeShow
      if (this.stakeShow) {
        // this.chatInfo.begin_time  开始挖矿时间
        // this.chatInfo.end_time    结束挖矿时间

        systemTimeApi().then(res => {
          if (res.code == 0) {
            // this.currentTime = res.data.now_time // 服务器当前时间
            this.currentTime = res.data // 服务器当前时间
            let allTime = this.chatInfo.end_time - this.chatInfo.begin_time
            let now = this.currentTime - this.chatInfo.begin_time
            let num = now / allTime * 100
            let process = Math.round(num * 10) / 10 //进度条
            this.progress = process > 100 ? 100 : process
            this.time = (allTime - now) * 1000 //倒计时

            clearInterval(this.timer)
            this.timer = setInterval(() => {
              if (this.progress < 100) {
                this.currentTime += 1

                let nowTime = this.currentTime - this.chatInfo.begin_time
                let number = nowTime / allTime * 100
                this.time = (allTime - nowTime) * 1000 //倒计时
                this.progress = Math.round(number * 10) / 10
              } else {
                clearInterval(this.timer)
                this.progress = 100
              }
            }, 1000)
          }
        })
      } else {
        this.progress = 0
        this.time = 0
        clearInterval(this.timer)
      }
    }
  },
  beforeDestroy () {
    console.log('挖矿组件即将进入销毁阶段-----');
    clearInterval(this.timer)
  },
  methods: {
    close () {
      this.$emit('stakeClose');
    },
    // 结束挖矿
    goToScore (status) {
      if (this.progress < 100) {
        return
      }
      this.stopLoading = true
      StartScoreApi({
        chat_id: this.chatInfo.id,
      }).then(res => {
        if (res.code == 0) {
          this.$emit('diggingOre', status)
        }
        this.stopLoading = false
        this.$toast(res.msg);
      })
    },
    /* 退出房间 */
    out () {
      getGroupProfile(this.chatInfo.im_group_id).then((imResponse) => {
        let data = imResponse.data.group
        if(data.memberCount > 2){
          this.$dialog.confirm({
            title: this.$t('提示'),
            message: this.$t('请确认是否停止聊天，此操作将会退出聊天室并且不会获得收益'),
            confirmButtonText:this.$t('确定'),
            cancelButtonText:this.$t('取消'),
          }).then(() => {
            isCanOutApi().then(resu => {
              if(resu.code == 0){
                quitGroup(this.chatInfo.im_group_id).then((res) => {
                  if (res.code == 0) {
                    let timer = setTimeout(() => {
                      clearTimeout(timer)
                      this.$emit('getChatInfo')
                    }, 500)
                    this.close()
                    this.$toast(this.$t('成功'));
                  }
                }).catch(err => {
                  this.$toast(err);
                });
              }else{
                this.$toast(resu.msg);
              }
            })
          }).catch(() => {
            // on cancel
          });
        }else{
          this.$toast(this.$t('房间人数需要大于2,才能正常退出'));
        }
      }).catch(imError => {
        // 获取群详细资料失败的相关信息
        this.$toast(imError);
      });
    },
  }
}
</script>

<style lang="less" scoped>
.stake {
  .van-popup {
    width: 82%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 7.5px;
    .close {
      width: 22.5px;
      height: 22.5px;
      position: absolute;
      right: 7.5px;
      top: 7.5px;
      z-index: 999;
    }
  }
  .main {
    min-height: 547.5px;
    div {
      box-sizing: border-box;
    }
    padding: 18.75px 33.75px 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .roomTitle {
      font-family: 'TsangerYuYangT-W05';
      font-size: 30px;
    }
    .subTitle {
      font-family: 'TsangerYuYangT-W05';
      font-size: 12px;
      margin-bottom: 18.75px;
    }
    .circle {
      margin-bottom: 22.5px;
      /deep/.ep-legend--container {
        width: 100% !important;
        height: 100% !important;
        .represent {
          width: 88%;
          height: 88%;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #2b2b2b;
          .percen {
            font-family: 'REEJI-PinboGB-Flash';
            font-size: 50px;
          }
          .timeProcess {
            font-family: 'TsangerYuYangT-W04';
            font-size: 14px;
            color: #5c65cb;
          }
          .van-count-down {
            font-family: 'AeroMaticsDisplayItalic';
            font-size: 26px;
          }
        }
      }
      /deep/.ep-legend--value {
        display: none;
      }
    }
    .noRating{
      color: #e0220b;
      text-align: center;
      font-size: 12px;
      margin-bottom: 6px;
      font-weight: 900;
    }
    .btn {
      width: 100%;
      background-color: #a7f264;
      border-radius: 15px 3px 15px 3px;
      border: 2px solid rgba(0, 0, 0, 0.8);
      padding: 5.625px 0;
      text-align: center;
      font-family: 'REEJI-PinboGB-Flash';
      font-size: 22.5px;
      color: #2b2b2b;
    }
    .disable_stop {
      background-color: #2b2b2b;
      color: #fff;
    }
    .disable_stake {
      background-image: linear-gradient(#6c6c6c, #6c6c6c),
        linear-gradient(#a7f264, #a7f264);
      background-blend-mode: normal, normal;
    }
    .available_stop {
      background-color: #e0220b;
      color: #fff;
    }
    .available_stake {
      background-color: #a7f264;
      color: #2b2b2b;
    }
    .mar_btm_4 {
      margin-bottom: 15px;
    }

    .stakeBtm {
      width: 262.5px;
      min-height: 15px;
      position: absolute;
      bottom: 0;
      background-color: #a7f264;
      border-radius: 7.5px 7.5px 0 0;
      padding-top: 7.5px;
      .eye {
        width: 30px;
        height: 22.5px;
        border-radius: 50%;
        position: absolute;
        background-color: #a7f264;
        transform: translateX(-50%);
        left: 50%;
        top: -11.25px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 15px;
          height: 11.25px;
        }
      }
      .eyeClose {
        width: 45px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        background-color: #a7f264;
        transform: translateX(-50%);
        left: 50%;
        top: -18.75px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 26.25px;
          height: 18.75px;
        }
      }
      .player {
        display: flex;
        flex-direction: column;
        align-items: center;

        color: #2b2b2b;
        .onlinePlayer {
          font-family: 'REEJI-PinboGB-Flash';
          font-size: 26.25px;
          // margin-bottom: 1vw;
        }
        .number {
          font-family: 'AeroMaticsDisplayBoldItalic';
          font-size: 15px;
          margin-bottom: 7.5px;
        }
        .imgList {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          // width: 61vw;
          margin-bottom: 3.75px;
          .item {
            width: 41.25px;
            height: 41.25px;
            border-radius: 50%;
            border: 2px solid #2b2b2b;
            position: relative;
            margin-right: 18.75px;
            margin-bottom: 11.25px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .stamp {
              width: 18.75px;
              height: 18.75px;
              line-height: 18.75px;
              text-align: center;
              border-radius: 50%;
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 10px;
              color: #ffffff;
              background-color: #2b2b2b;
              position: absolute;
              bottom: -3.75px;
              right: -7.5px;
            }
          }
          .item:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 480px) {
  //在这里写非小屏幕设备的样式  -- 非手机
  .stake {
    .van-popup {
      width: 350px;
    }
  }
}
</style>
